import React, {Fragment, useEffect, useState} from 'react';
import dayjs from "dayjs";
import {useQuery} from "react-query";
import {api} from "../../../utils/api";
import {Helmet} from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import {Form} from "react-bootstrap";
import {Button, Input} from "reactstrap";
import DatePicker from "react-datepicker";
import {Calendar} from "react-feather";
import DataTable from "react-data-table-component";
import {debounce, throttle} from "lodash";

const ResellerOrder = () => {
    const aWeekAgo = new Date()
    aWeekAgo.setDate(aWeekAgo.getDate() - 14)

    const [totalRows, setTotalRows] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    // 정렬 필드
    const [sortField, setSortField] = useState('created_at');
    // 정렬 방식 (asc, desc)
    const [sortDirection, setSortDirection] = useState('DESC');

    const [startDate, setStartDate] = useState(aWeekAgo);
    const [endDate, setEndDate] = useState(new Date());
    const [query, setQuery] = useState('');
    const [tmpQuery, setTmpQuery] = useState(query);
    const [rebate, setRebate] = useState('');
    const [rebateOperator, setRebateOperator] = useState('>=');
    const [amountOperator, setAmountOperator] = useState('>=');
    const [amount, setAmount] = useState('');
    const [plan, setPlan] = useState('');
    const [planGroup, setPlanGroup] = useState('');

    const [searchField, setSearchField] = useState('user_email');

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'search_field':
                setSearchField(e.target.value);
                setQuery('');
                setTmpQuery('');
                break;
            case 'user_email':
            case 'remarks':
                setQuery(e.target.value);
                setTmpQuery(e.target.value);
                break;
            case 'plan':
                setPlan(e.target.value);
                break;
            case 'plan_group':
                setPlanGroup(e.target.value);
                break;
        }
    }

    const columns = [
        {
            name: '번호',
            cell: (row, index) => new Intl.NumberFormat('ko-KR').format(currentPageSize * (currentPage - 1) + index + 1),
        },
        {
            name: '대리 판매자 이메일',
            selector: row => row?.reseller?.email,
            sortable: true,
        },
        {
            name: '개통 고객 이메일',
            selector: row => row?.user?.email,
            sortable: true,
        },
        {
            name: '요금제',
            selector: row => row?.plan?.name,
            sortable: true,
        },
        {
            name: '개통 금액',
            selector: row => new Intl.NumberFormat('ko-KR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(row.total_amount / 2),
            sortable: true,
        },
        {
            name: '리베이트 금액',
            selector: row => new Intl.NumberFormat('ko-KR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(row.rebate),
            sortable: true,
        },
        {
            name: '개통 시간',
            selector: row => dayjs(new Date(row.created_at * 1000)).format('YYYY-MM-DD HH:mm:ss'),
            sortable: true,
        },
    ];

    const { data: planList, isFetching: planListLoading } = useQuery('getPlanList', async () => {
        return await api(localStorage.auth_data).get('/v1/rhksflwk/plan/fetch')
    })

    const { data: planGroupList } = useQuery('getPlanGroupList', async () => {
        return await api(localStorage.auth_data).get('/v1/rhksflwk/server/group/fetch')
    })

    const { data: orderList, isFetching: isLoading, refetch } = useQuery('getOrderList', async () => {
        const filters = [
            {
                key: 'server_group_id',
                condition: '=',
                value: planGroup,
            },
            {
                key: 'plan_id',
                condition: '=',
                value: plan,
            },
            {
                key: 'created_at',
                condition: '>=',
                value: startDate,
            },
            {
                key: 'created_at',
                condition: '<=',
                value: endDate,
            },
            {
                key: 'rebate',
                condition: rebateOperator,
                value: rebate,
            },
            {
                key: 'total_amount',
                condition: amountOperator,
                value: amount,
            },
        ]

        switch (searchField) {
            case 'user_email':
            case 'remarks':
                filters.push({
                    key: searchField,
                    condition: 'like',
                    value: query,
                });
                break;
        }

        const response = await api(localStorage.auth_data).get(`/v2/rhksflwk/reseller/reseller_order/list?pageSize=${currentPageSize}&current=${currentPage}`, {
            params: {
                sort: sortField,
                sort_type: sortDirection,
                filter: filters,
            }
        })

        setTotalRows(response?.data?.data?.total || 0);

        return response?.data
    }, {
        gcTime: 1,
    })

    const  handleSearch = async (e) => {
        e.preventDefault()

        await refetch()
    }

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);

        await refetch();
    };

    useEffect(() => {
        debounce(async () => {
            await refetch();
        }, 300)();
        return setQuery(tmpQuery)
    }, [searchField, tmpQuery, plan, planGroup, startDate, endDate, amountOperator, amount, rebateOperator, rebate, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        throttle(async () => {
            await refetch();
        }, 100)();
    }, [currentPageSize]);

    return (<Fragment>
        <Helmet>
            <title>대리판매자 개통 내역</title>
        </Helmet>
        <Breadcrumb title="대리판매자 개통 내역" parent="대시보드" />
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <Form onSubmit={handleSearch}>
                                <Form.Group className="row my-1">
                                    <Form.Label className="col-lg-2 d-flex align-items-center">검색</Form.Label>
                                    <div className="col">
                                        <Form.Select defaultValue="user_email" onChange={(e) => setSearchField(e.target.value)}>
                                            <option value="user_email">개통 고객 이메일</option>
                                            <option value="remarks">비고</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Control name={searchField} type={searchField === 'user_email' ? 'email': 'text'} value={query} onChange={handleChange}  />
                                    </div>
                                </Form.Group>
                                <Form.Group className="row my-1">
                                    <Form.Label className="col-lg-2 d-flex align-items-center">요금제</Form.Label>
                                    <div className="col">
                                        <Input name="plan_group" type="select" defaultValue={planGroup} onChange={handleChange}>
                                            <option value="">전체 요금제 그룹</option>
                                            {planGroupList?.data?.data.map((group) => (
                                                <option key={group.id} value={group.id}>{group.name}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="col">
                                        <Input name="plan" type="select" defaultValue={plan} onChange={handleChange}>
                                            <option value="">전체 요금제</option>
                                            {!planListLoading && planList?.data?.data.filter((plan) => {
                                                return planGroup === '' || (planGroup !== '' && parseInt(plan.group_id, 10) === parseInt(planGroup, 10))
                                            }).map((plan) => (
                                                <option key={plan.id} value={plan.id}>{plan.name}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row my-1">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <Form.Label>개통 시간</Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            showIcon
                                            icon={<Calendar/>}
                                            className="form-control digits"
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            showTimeSelect
                                            placeholderText="시작일"
                                            locale="ko"
                                            dateFormat="P"
                                        />
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            showIcon
                                            icon={<Calendar />}
                                            className="form-control digits"
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            showTimeSelect
                                            placeholderText="종료일"
                                            locale="ko"
                                            dateFormat="P"
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="row my-1">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <Form.Label>개통 금액</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Select defaultValue={amountOperator} onChange={(e) => setAmountOperator(e.target.value)}>
                                            <option value=">=">이상</option>
                                            <option value="<=">이하</option>
                                            <option value="<">미만</option>
                                            <option value=">">초과</option>
                                            <option value="=">일치</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Control
                                            type="text"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="row my-1">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <Form.Label>리베이트 금액</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Select defaultValue={rebateOperator} onChange={(e) => setRebateOperator(e.target.value)}>
                                            <option value=">=">이상</option>
                                            <option value="<=">이하</option>
                                            <option value="<">미만</option>
                                            <option value=">">초과</option>
                                            <option value="=">일치</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col">
                                        <Form.Control
                                            type="text"
                                            value={rebate}
                                            onChange={(e) => setRebate(e.target.value)}
                                        />
                                    </div>
                                </Form.Group>
                                <div className="my-2 text-end">
                                    <Button color="dark" onClick={() =>  {
                                        setQuery('')
                                        setPlan('')
                                        setPlanGroup('')
                                        setStartDate(new Date().setDate(aWeekAgo.getDate() - 14))
                                        setEndDate(new Date())
                                        setAmountOperator('>=')
                                        setAmount('')
                                        setRebateOperator('>=')
                                        setRebate('')
                                    }}>초기화</Button>

                                </div>
                            </Form>
                            <DataTable
                                columns={columns}
                                data={orderList?.data?.data}
                                sortServer
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationComponentOptions={{ rowsPerPageText: '한 페이지에 보이는 개수', rangeSeparatorText: '중' }}
                                progressPending={isLoading}
                                fixedHeader
                                onChangePage={page => setCurrentPage(page)}
                                onChangeRowsPerPage={size => setCurrentPageSize(size)}
                                onSort={handleSort}
                                noDataComponent={<div style={{ margin: 15 }}>대리판매자 개통 내역이 없습니다.</div>}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Fragment>);
}

export default ResellerOrder;