import React, {Fragment, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {useQuery} from "react-query";
import dayjs from "dayjs";

import Breadcrumb from '../../common/breadcrumb';
import {api} from "../../../utils/api";
import {Helmet} from "react-helmet";
import {throttle} from "lodash";


const PointHistory = () => {
    const [totalRows, setTotalRows] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    // 정렬 필드
    const [sortField, setSortField] = useState('created_at');
    // 정렬 방식 (asc, desc)
    const [sortDirection, setSortDirection] = useState('DESC');

    const data = []

    const { data: pointHistory, isFetching: isLoading, refetch } = useQuery(['pointHistory', localStorage.auth_data ], async () => {
        const response = await api(localStorage.auth_data).get(`/v2/rhksflwk/reseller/point/history?pageSize=${currentPageSize}&current=${currentPage}`, {
            params: {
                sort: sortField,
                sort_type: sortDirection,
            }
        });

        setTotalRows(response?.data?.data?.total || 0);

        return response.data?.data?.data;
    }, {
        gcTime: 1,
    });

    useEffect(() => {
        throttle(async () => {
            await refetch();
        }, 100)();
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        throttle(async () => {
            await refetch();
        }, 100)();
    }, [currentPageSize]);

    const columns = [
        {
            name: '번호',
            cell: (row, index) => new Intl.NumberFormat('ko-KR').format(currentPageSize * (currentPage - 1) + index + 1),
        },
        {
            name: '사용자 이메일',
            selector: row => row.user?.email,
            sortable: true,
            sortField: 'user.email',
        },
        {
            name: '내용',
            selector: row => row.content,
        },
        {
            name: '변동 전 잔액',
            selector: row => new Intl.NumberFormat('ko-KR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(row.before_point / 10 / 10),
        },
        {
            name: '변동 금액',
            selector: row => new Intl.NumberFormat('ko-KR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(row.point),
            conditionalCellStyles: [
                {
                    when: row => row.point < 0,
                    style: { color: 'red' },
                },
                {
                    when: row => row.point > 0,
                    style: { color: 'blue' },
                }
            ],

        },
        {
            name: '변동 후 잔액',
            selector: row => new Intl.NumberFormat('ko-KR', {
                style: 'decimal',
                minimumFractionDigits: 2,
            }).format(row.after_point / 10 / 10),
        },
        {
            name: '일시',
            selector: row => dayjs(new Date(row.created_at * 1000)).format('YYYY-MM-DD HH:mm:ss'),
            sortable: true,
            sortField: 'created_at',
        },
    ];

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);

        await refetch();
    };

    return (
        <Fragment>
            <Helmet>
                <title>포인트 증감 내역</title>
            </Helmet>

            <Breadcrumb title="포인트 증감 내역" parent="대시보드" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <DataTable
                                columns={columns}
                                data={pointHistory}
                                sortServer
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationComponentOptions={{ rowsPerPageText: '한 페이지에 보이는 개수', rangeSeparatorText: '중' }}
                                noDataComponent={<div style={{ margin: 15 }}>포인트 증감 내역이 없습니다.</div>}
                                progressPending={isLoading}
                                onChangePage={page => setCurrentPage(page)}
                                onChangeRowsPerPage={size => setCurrentPageSize(size)}
                                onSort={handleSort}
                                fixedHeader />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PointHistory;