import React, {useState, Fragment, useEffect, createRef} from 'react';
import DataTable from 'react-data-table-component';
import {
    Button,
    ButtonGroup,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup, Label, Input, FormFeedback,
    Col,
    Tooltip
} from "reactstrap";
import {Form} from "react-bootstrap";

import { useQuery, useQueryClient } from 'react-query';
import dayjs from "dayjs";

import Breadcrumb from '../../common/breadcrumb';
import {AlertCircle, Delete, Edit, Plus, User, UserPlus} from "react-feather";
import {Helmet} from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import { throttle, debounce } from "lodash";

import { api } from "../../../utils/api";
import axios from "axios";
import {useSelector} from "react-redux";

const Project = () => {
    const queryClient = useQueryClient();

    const user = useSelector(state => state.UserReducer?.user);
    const isMerchant = user?.is_merchant;
    const isReseller = user?.is_reseller;

    const [totalRows, setTotalRows] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    // 정렬 필드
    const [sortField, setSortField] = useState('created_at');
    // 정렬 방식 (asc, desc)
    const [sortDirection, setSortDirection] = useState('asc');

    const [modal, setModal] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUserIdx, setDeleteUserIdx] = useState('')

    const [userIdx, setUserIdx] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [planGroupId, setPlanGroupId] = useState('');
    const [planId, setPlanId] = useState('');
    const [period, setPeriod] = useState('')
    const [expiredAt, setExpiredAt] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    // 비고
    const [remarks, setRemarks] = useState('');

    const [orderEmail, setOrderEmail] = useState('');
    const [orderPlanGroupId, setOrderPlanGroupId] = useState('');
    const [orderPlanId, setOrderPlanId] = useState('');
    const [orderPeriod, setOrderPeriod] = useState('');
    const [orderExpiredAt, setOrderExpiredAt] = useState('');
    const [orderTotalAmount, setOrderTotalAmount] = useState('');

    const [errors, setErrors] = useState({});

    const [searchField, setSearchField] = useState('user_email');
    const [expiresInTime, setExpiresInTime] = useState('');
    const [query, setQuery] = useState('');
    const [tmpQuery, setTmpQuery] = useState(query);

    const emailRef = createRef();
    const passwordRef = createRef();
    const planIdRef = createRef();
    const periodRef = createRef();
    const expiredAtRef = createRef();
    const totalAmountRef = createRef();
    const remarksRef = createRef();

    const orderEmailRef = createRef();
    const orderPlanGroupIdRef = createRef();
    const orderPlanIdRef = createRef();
    const orderPeriodRef = createRef();
    const orderExpiredAtRef = createRef();
    const orderTotalAmountRef = createRef();

    const [tooltipOpen, setTooltipOpen] = useState({});
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState({});
    const [orderTooltipOpen, setOrderTooltipOpen] = useState({});

    const toggleTooltip = (key) => setTooltipOpen({
        ...tooltipOpen,
        [key] : !!!tooltipOpen[key],
    });
    const toggleDeleteTooltip = (key) => setDeleteTooltipOpen({
        ...deleteTooltipOpen,
        [key]: !!!deleteTooltipOpen[key],
    });
    const toggleOrderTooltip = (key) => setOrderTooltipOpen({
        ...orderTooltipOpen,
        [key]: !!!orderTooltipOpen[key],
    });


    const { data: userList, refetch, isFetching: isLoading } = useQuery({
        queryKey: [ 'getUserList', localStorage.auth_data ],
        queryFn: async () => {
            const filters = [];

            switch (searchField) {
                case 'user_email':
                case 'remarks':
                    filters.push({
                        key: searchField,
                        condition: 'like',
                        value: tmpQuery,
                    });
                    break;
            }

            if(expiresInTime) {
                filters.push({
                    key: 'expiresInTime',
                    condition: '',
                    value: expiresInTime,
                });
            }

            const response = await api(localStorage.auth_data).get(`/v2/rhksflwk/reseller/user/list?pageSize=${currentPageSize}&current=${currentPage}`, {
                params: {
                    sort: sortField,
                    sort_type: sortDirection,
                    filter: filters,
                }
            })

            setTotalRows(response?.data.total || 0);

            return response?.data?.data || []
        },
        enabled: false,
    }, {
        gcTime: 1,
    })

    const { data: groupList } = useQuery({
        queryKey: 'getGroupList',
        queryFn: async () => {
            return await api(localStorage.auth_data).get('/v1/rhksflwk/server/group/fetch')
        },
        staleTime: 30000,
    })

    const { data: planList } = useQuery({
        queryKey: 'getPlanList',
        queryFn: async () => {
            return await api(localStorage.auth_data).get('/v1/rhksflwk/plan/fetch', {})
        },
        staleTime: 30000,
    })

    const getGroupName = (groupId) => {
        return groupList?.data?.data?.find(group => group.id === groupId)?.name
    }

    const handleEditUser = row => {
        setModal(true)
        setUserIdx(row.id)
        setEmail(row.email)
        setPlanId(row.plan_id)
        setPeriod(row.period)
        setExpiredAt(dayjs(new Date(row.expired_at * 1000)).format('YYYY-MM-DD'))
        setTotalAmount(row?.order?.total_amount)
        setRemarks(row?.remarks)
    }

    const handleDeleteUser = async () => {
        try {
            const response = await api(localStorage.auth_data).post('/v2/rhksflwk/reseller/user/delete', {
                id: deleteUserIdx
            })

            if(response.data.result) {
                setDeleteModal(false)
                toast.success('사용자 삭제가 완료되었습니다.')
                await refetch()
            } else {
                toast.error('사용자 삭제에 실패하였습니다.')
            }
        } catch(error) {
            if (axios.isAxiosError(error)) {
                toast.error(error.response?.data.message)
            }
        }
    }

    const columns = [
        {
            name: '번호',
            cell: (row, index) => new Intl.NumberFormat('ko-KR').format(currentPageSize * (currentPage - 1) + index + 1),
        },
        {
            name: '이메일',
            selector: row => row.email,
            sortable: true,
            sortField: 'email',
        },
        {
            name: '계정 상태',
            selector: row => row.banned ? '정지' : '정상',
            sortable: true,
            sortField: 'banned',
        },
        {
            name: '요금제',
            selector: row => row.plan_name,
            sortable: true,
            sortField: 'plan_name',
        },
        {
            name: '요금제 그룹',
            selector: row => getGroupName(row.plan.group_id),
            sortable: true,
            sortField: 'plan.group_id',
        },
        {
            name: '사용한 데이터(GB)',
            selector: row => (Math.floor((row.total_used / 1024 / 1024 / 1024) * 10 * 10) / 10 / 10 ).toFixed(2) + 'GB',
            sortable: true,
            sortField: 'total_used',
        },
        {
            name: '총 데이터(GB)',
            selector: row => (Math.floor((row.transfer_enable / 1024 / 1024 / 1024) * 10 * 10) / 10 / 10 ).toFixed(2) + 'GB',
            sortable: true,
            sortField: 'transfer_enable',
        },
        {
            name: '만료일',
            selector: row => dayjs(new Date(row.expired_at * 1000)).format('YYYY-MM-DD'),
            sortable: true,
            sortField: 'expired_at',
        },
        {
            name: '가입일',
            selector: row => dayjs(new Date(row.created_at * 1000)).format('YYYY-MM-DD'),
            sortable: true,
            sortField: 'created_at',
        },
        {
            name: '편집',
            button: true,
            cell: (row) => {
                return <>
                    <Tooltip target={`edit-${row.id}`}
                             isOpen={!!tooltipOpen[row.id]}
                             toggle={() => toggleTooltip(row.id)}>
                        수정
                    </Tooltip>
                    <Tooltip target={`delete-${row.id}`}
                             isOpen={!!deleteTooltipOpen[row.id]}
                             toggle={() => toggleDeleteTooltip(row.id)}>
                        삭제
                    </Tooltip>
                    <Tooltip target={`order-${row.id}`}
                                isOpen={!!orderTooltipOpen[row.id]}
                                toggle={() => toggleOrderTooltip(row.id)}>
                        개통(연장)
                    </Tooltip>
                    <a href="#" id={`edit-${row.id}`} onClick={e => {
                        e.preventDefault()

                        handleEditUser(row)
                    }} style={{ marginRight: 10 }}><Edit width={16} height={16} /></a>
                    <a href="#" id={`delete-${row.id}`} onClick={e => {
                        e.preventDefault()

                        setDeleteModal(true);
                        setDeleteUserIdx(row.id);
                    }} style={{ marginRight: 10 }}><Delete width={16} height={16} /></a>
                    <a href="#" id={`order-${row.id}`} onClick={e => {
                        e.preventDefault()

                        setOrderEmail(row.email);
                        setOrderModal(true);
                    }}>
                        <Plus width={16} height={16} />
                    </a>
            </> }
        }
    ];

    const toggle = () => setModal(!modal);
    const orderToggle = () => setOrderModal(!orderModal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const handleAddUser = async (e) => {
        e.preventDefault()

        if(!email) {
            emailRef?.current?.focus()
            setErrors({ email: '이메일을 입력하세요' })
            return false
        }

        if(!userIdx && !password) {
            passwordRef?.current?.focus()
            setErrors({ password: '비밀번호를 입력하세요' })
            return false
        }

        if(password && password.length < 8) {
            passwordRef?.current?.focus()
            setErrors({ password: '비밀번호는 8자리 이상 입력하세요' })
            return false
        }

        if(!userIdx && !planId) {
            planIdRef?.current?.focus()
            setErrors({ planId: '요금제를 선택하세요' })
            return false
        }

        if(!userIdx && !period) {
            periodRef?.current?.focus()
            setErrors({ period: '사용기간을 선택하세요' })
            return false
        }

        if(!userIdx && !expiredAt) {
            expiredAtRef?.current?.focus()
            setErrors({ expiredAt: '사용기간을 선택하세요' })
            return false
        }

        if(!userIdx && !window.confirm('정말 사용자를 추가하시겠습니까?')) {
            return false;
        }

        try {
            const apiURL = userIdx ? '/v2/rhksflwk/reseller/user/update' : '/v2/rhksflwk/reseller/user/add'
            const response = await api(localStorage.auth_data).post(apiURL, {
                email,
                password,
                plan_id: planId,
                period,
                expired_at: expiredAt,
                remarks,
            })

            if(response.data.result) {
                toast.success(userIdx ? '사용자가 수정되었습니다.' : '사용자 추가가 완료되었습니다.')
                await refetch()
                setEmail('')
                setPassword('')
                setPlanId('')
                setPeriod('month_price')
                setExpiredAt('')
                setTotalAmount('')
                setRemarks('')
                setModal(false)

                // balance에 대한 캐시를 비우기 위해, invalidateQueries 호출
                await queryClient.invalidateQueries({queryKey: ['user']});
            } else {
                toast.error(response.data.message);
            }
        } catch(error) {
            if (axios.isAxiosError(error)) {
                toast.error(error.response?.data.message)
            }
        }

        return false
    }

    const handleOrder = async (e) => {
        e.preventDefault()

        if(!orderEmail) {
            orderEmailRef?.current?.focus()
            setErrors({ orderEmail: '이메일을 입력하세요' })
            return false
        }

        if(!orderPlanGroupId) {
            orderPlanGroupIdRef?.current?.focus()
            setErrors({ orderPlanGroupId: '요금제를 선택하세요'})
            return false
        }

        if(!orderPlanId) {
            orderPlanIdRef?.current?.focus()
            setErrors({ planId: '요금제를 선택하세요' })
            return false
        }

        if(!orderPeriod) {
            orderPeriodRef?.current?.focus()
            setErrors({ orderPeriod: '사용기간을 선택하세요' })
            return false
        }


        if(!window.confirm(`정말 ${orderEmail}님의 요금제(${orderSelectedPlan.name})를 개통하시겠습니까?`)) {
            return false;
        }

        try {
            const response = await api(localStorage.auth_data).post('/v2/rhksflwk/reseller/order/add', {
                email: orderEmail,
                plan_id: orderPlanId,
                period: orderPeriod,
                expired_at: orderExpiredAt,
            })

            if(response.data.result) {
                toast.success('요금제 개통이 완료되었습니다.')
                await refetch()
                setOrderEmail('')
                setOrderPlanId('')
                setOrderModal(false)

                // balance에 대한 캐시를 비우기 위해, invalidateQueries 호출
                await queryClient.invalidateQueries({queryKey: ['user']});
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(error.response?.data.message)
            }
        }


        return false
    }

    useEffect(() => {
        if(localStorage.token) {
            refetch().then();
        }
    }, [ localStorage.token ]);

    useEffect(() => {
        if(!modal) {
            setUserIdx('')
            setEmail('')
            setPassword('')
            setPlanGroupId('')
            setPlanId('')
            setExpiredAt('')
            setPeriod('')
            setTotalAmount('')
        }
    }, [modal]);

    // 금액 재계산
    const recalculateTotalAmount = (planId, period) => {
        // 선택한 요금제 정보
        const selectedPlan = planList?.data?.data?.find(plan => parseInt(plan.id, 10) === parseInt(planId, 10));

        if(selectedPlan) {
            // 사용기간을 선택하지 않은 경우, 가격을 리셋
            if(period === '') {
                setExpiredAt('');
                setTotalAmount('');
                return;
            }

            const price = (period === '72H' ? parseInt(selectedPlan.month_price, 10) : parseInt(selectedPlan[period], 10)) / 10 / 10;
            const rebate = (period === '72H' ? 0 : parseInt(selectedPlan[period + '_rebate'], 10)) / 10 / 10;

            // 판매자인 경우
            if(isMerchant) {
                setTotalAmount(price.toFixed(2));
                // 대리판매자인 경우
            } else if (isReseller){
                setTotalAmount((price + rebate).toFixed(2));
            }
        } else {
            setTotalAmount('');
        }
    }

    // 금액 재계산
    const recalculateOrderTotalAmount = (planId, period) => {
        // 선택한 요금제 정보
        const selectedPlan = planList?.data?.data?.find(plan => parseInt(plan.id, 10) === parseInt(orderPlanId, 10));

        if(selectedPlan) {
            // 사용기간을 선택하지 않은 경우, 가격을 리셋
            if(period === '') {
                setExpiredAt('');
                setTotalAmount('');
                return;
            }

            const price = (period === '72H' ? parseInt(selectedPlan.month_price, 10) : parseInt(selectedPlan[period], 10)) / 10 / 10;
            const rebate = (period === '72H' ? 0 : parseInt(selectedPlan[period + '_rebate'], 10)) / 10 / 10;

            // 판매자인 경우
            if(isMerchant) {
                setOrderTotalAmount(price.toFixed(2));
                // 대리판매자인 경우
            } else if (isReseller){
                setOrderTotalAmount((price  +  rebate).toFixed(2));
            }
        } else {
            setTotalAmount('');
        }
    }

    const handleChange = e => {
        setTmpQuery(e.target.value)
        setQuery(e.target.value)
    }

    const handleSearch = async (e) => {
        e.preventDefault()

        await refetch()
    }

    useEffect(() => {
        debounce(async () => {
            await refetch();
        }, 600)();
    }, [searchField, tmpQuery, expiresInTime, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        throttle(async () => {
            await refetch();
        }, 100)();
    }, [currentPageSize]);

    useEffect(() => {
        setPlanId('');
        setTotalAmount('');
        setPeriod('');
    }, [ planGroupId ]);

    useEffect(() => {
        recalculateTotalAmount(planId, period);
    }, [ planGroupId, planId, period ]);

    useEffect(() => {
        recalculateOrderTotalAmount(orderPlanId, orderPeriod);
    }, [ orderPlanGroupId, orderPlanId, orderPeriod ]);

    useEffect(() => {
        setOrderPlanId('');
        setOrderTotalAmount('');
        setOrderPeriod('');
    }, [ orderPlanGroupId ]);

    const selectedPlan = planList?.data?.data?.find(plan => parseInt(plan.id, 10) === parseInt(planId, 10));
    const orderSelectedPlan = planList?.data?.data?.find(plan => parseInt(plan.id, 10) === parseInt(orderPlanId, 10));

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);

        await refetch();
    };

    return (
        <Fragment>
            <ToastContainer />
            <Helmet>
                <title>사용자 관리</title>
            </Helmet>

            <Breadcrumb title="사용자 관리" parent="대시보드" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <Form onSubmit={handleSearch}>
                                    <Form.Group className="my-1">
                                        <InputGroup>
                                            <Form.Select defaultValue="user_email" onChange={(e) => setSearchField(e.target.value)}>
                                                <option value="user_email">개통 고객 이메일</option>
                                                <option value="remarks">비고</option>
                                            </Form.Select>
                                            <Form.Control type={searchField === 'user_email' ? 'email': 'text'} value={tmpQuery} onChange={handleChange} autoComplete={false}/>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="my-2">
                                        <ButtonGroup>
                                            <Button outline active={expiresInTime === ''} onClick={() => setExpiresInTime('')}>전체</Button>
                                            <Button outline active={expiresInTime === '3d'} onClick={() => setExpiresInTime('3d')}>3일 이내 만료</Button>
                                            <Button outline active={expiresInTime === '7d'} onClick={() => setExpiresInTime('7d')}>7일 이내 만료</Button>
                                            <Button outline active={expiresInTime === '14d'} onClick={() => setExpiresInTime('14d')} >14일 이내 만료</Button>
                                            <Button outline active={expiresInTime === '30d'} onClick={() => setExpiresInTime('30d')}>30일 이내 만료</Button>
                                        </ButtonGroup>
                                    </Form.Group>
                                </Form>
                                <div style={{ height: 10 }}></div>
                                <DataTable
                                    subHeader={true}
                                    subHeaderComponent={
                                        <div className="d-flex justify-content-between w-100 mb-2">
                                            <h2><User/> 사용자 관리</h2>
                                            <Button onClick={toggle}>
                                                <UserPlus width={16} height={16} style={{verticalAlign: 'middle'}}/> 사용자
                                                추가
                                            </Button>
                                        </div>
                                    }
                                    subHeaderAlign="left"
                                    noDataComponent={<div style={{ margin: 15 }}>사용자가 없습니다.</div>}
                                    columns={columns}
                                    data={userList}
                                    sortServer
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    paginationComponentOptions={{ rowsPerPageText: '한 페이지에 보이는 개수', rangeSeparatorText: '중' }}
                                    onChangePage={page => setCurrentPage(page)}
                                    onChangeRowsPerPage={size => setCurrentPageSize(size)}
                                    onSort={handleSort}
                                    progressPending={isLoading}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={orderModal}>
                <Form onSubmit={handleOrder}>
                    <ModalHeader toggle={orderToggle}>요금제 개통(연장)</ModalHeader>
                    <ModalBody>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>이메일</Label>
                                <Input type="email" ref={orderEmailRef} value={orderEmail} disabled placeholder="이메일을 입력하세요" invalid={!!errors?.email} onChange={e => {
                                    setOrderEmail(e.target.value);
                                    setErrors({
                                        ...errors,
                                        email: ''
                                    });
                                }} />
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Label>요금제</Label>
                            <Col>
                            <Input type="select" ref={orderPlanGroupIdRef} invalid={!!errors?.planGroupId} onChange={e => {
                                    setOrderPlanGroupId(e.target.value);
                                    setErrors({
                                        ...errors,
                                        orderPlanGroupId: ''
                                    });
                                }}>
                                    <option>요금제 그룹 선택</option>
                                    {groupList?.data?.data?.map((group, index) => (
                                        !group.name.includes('테스트') && <option value={group.id} key={index}>{group.name}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Input type="select" ref={orderPlanIdRef} value={orderPlanId} invalid={!!errors?.orderPlanId} onChange={(e) => {
                                    setOrderPlanId(e.target.value);
                                    setErrors({
                                        ...errors,
                                        orderPlanId: ''
                                    });
                                }}>
                                    <option value="">요금제 선택</option>
                                    {planList?.data?.data?.filter((plan) => {
                                        return parseInt(plan?.group_id, 10) === parseInt(orderPlanGroupId, 10)
                                    }).map((plan, index) => (
                                        <option value={plan.id} key={index}>{plan.name}</option>
                                    ))}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>사용기간</Label>
                                <Input type="select" id="orderPeriod" ref={orderPeriodRef} value={orderPeriod} invalid={!!errors.orderPeriod} onChange={(e) => {
                                    setOrderPeriod(e.target.value);
                                    setErrors({
                                        ...errors,
                                        orderPeriod: ''
                                    });

                                    switch (e.target.value) {
                                        case 'month_price':
                                            // 현재 날짜 기준으로 + 1 month
                                            setOrderExpiredAt(dayjs(new Date(new Date().getTime())).add(1, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'quarter_price':
                                            // 현재 날짜 기준으로 + 3 month
                                            setOrderExpiredAt(dayjs(new Date(new Date().getTime())).add(3, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'half_year_price':
                                            // 현재 날짜 기준으로 + 6 month
                                            setOrderExpiredAt(dayjs(new Date(new Date().getTime())).add(6, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'year_price':
                                            // 현재 날짜 기준으로 + 1 year
                                            setOrderExpiredAt(dayjs(new Date(new Date().getTime())).add(1, 'year').format('YYYY-MM-DD'));
                                            break;
                                        case '72H':
                                            setOrderExpiredAt(dayjs(new Date(new Date().getTime())).add(72, 'hours').format('YYYY-MM-DD'));
                                            break;
                                    }
                                }}>
                                    <option value="">사용기간 선택</option>
                                    {orderSelectedPlan?.name.includes('테스트') && <option value="72H">72시간</option>}
                                    {!orderSelectedPlan?.name.includes('테스트') && orderSelectedPlan?.month_price && <option value="month_price">1개월</option>}
                                    {!orderSelectedPlan?.name.includes('테스트') && orderSelectedPlan?.quarter_price && <option value="quarter_price">3개월</option>}
                                    {!orderSelectedPlan?.name.includes('테스트') && orderSelectedPlan?.half_year_price && <option value="half_year_price">6개월</option>}
                                    {!orderSelectedPlan?.name.includes('테스트') && orderSelectedPlan?.year_price && <option value="year_price">1년</option>}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>마감일자</Label>
                                <Input type="date" ref={orderExpiredAtRef} value={orderExpiredAt} invalid={!!errors?.orderExpiredAt} disabled />
                                {errors.orderExpiredAt && <FormFeedback>{errors.orderExpiredAt}</FormFeedback>}
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>비용</Label>
                                <Input type="text" ref={orderTotalAmountRef} value={orderTotalAmount} disabled invalid={!!errors.orderTotalAmount} onChange={(e) => setOrderTotalAmount(e.target.value)} />
                                {errors.orderTotalAmount && <FormFeedback>{errors.orderTotalAmount}</FormFeedback>}
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary">
                            확인
                        </Button>{' '}
                        <Button color="light" onClick={orderToggle}>
                            취소
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={modal} toggle={toggle}>
                <Form onSubmit={handleAddUser}>
                <ModalHeader toggle={toggle}>
                    {userIdx && '사용자 수정' || '사용자 추가'}
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="position-relative" row>
                        <Col>
                            <Label>이메일</Label>
                            <Input type="email" ref={emailRef} disabled={!!userIdx} value={email} placeholder="이메일을 입력하세요" invalid={!!errors?.email} onChange={(e) => setEmail(e.target.value)} />
                            {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="position-relative" row>
                        <Col>
                            <Label>비밀번호</Label>
                            <Input type="password" ref={passwordRef} value={password} placeholder="비밀번호를 입력하세요" invalid={!!errors?.password} onChange={(e) => setPassword(e.target.value)} />
                            {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                        </Col>
                    </FormGroup>
                    {!!!userIdx && (
                        <>
                        <FormGroup className="position-relative" row>
                            <Label>요금제</Label>
                            <Col>
                                <Input type="select" defaultValue={planGroupId} invalid={!!errors?.planId} onChange={e => setPlanGroupId(e.target.value)}>
                                    <option value="">요금제 그룹 선택</option>
                                    {groupList?.data?.data?.map((group, index) => (
                                        !group.name.includes('테스트') && <option value={group.id} key={index}>{group.name}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col>
                                <Input type="select" defaultValue="" invalid={!!errors?.planId} onChange={e => setPlanId(e.target.value)}>
                                    {errors.planId && <FormFeedback>{errors.planId}</FormFeedback>}
                                    <option value="">요금제 선택</option>
                                    {planList?.data?.data?.filter((plan) => {
                                        return parseInt(plan?.group_id, 10) === parseInt(planGroupId, 10)
                                    }).map((plan, index) => (
                                        <option value={plan.id} key={index}>{plan.name}</option>
                                    ))}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>사용기간</Label>
                                <Input type="select" id="period" defaultValue={period} onChange={(e) => {
                                    switch (e.target.value) {
                                        case 'month_price':
                                            // 현재 날짜 기준으로 + 1 month
                                            setExpiredAt(dayjs(new Date(new Date().getTime())).add(1, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'quarter_price':
                                            // 현재 날짜 기준으로 + 3 month
                                            setExpiredAt(dayjs(new Date(new Date().getTime())).add(3, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'half_year_price':
                                            // 현재 날짜 기준으로 + 6 month
                                            setExpiredAt(dayjs(new Date(new Date().getTime())).add(6, 'month').format('YYYY-MM-DD'));
                                            break;
                                        case 'year_price':
                                            // 현재 날짜 기준으로 + 1 year
                                            setExpiredAt(dayjs(new Date(new Date().getTime())).add(1, 'year').format('YYYY-MM-DD'));
                                            break;
                                        case '72H':
                                            setExpiredAt(dayjs(new Date(new Date().getTime())).add(72, 'hours').format('YYYY-MM-DD'));
                                            break;
                                    }

                                    setPeriod(e.target.value);
                                }}>
                                    <option value="">사용기간 선택</option>
                                    {selectedPlan?.name.includes('테스트') && <option value="72H">72시간</option>}
                                    {!selectedPlan?.name.includes('테스트') && selectedPlan?.month_price && <option value="month_price">1개월</option>}
                                    {!selectedPlan?.name.includes('테스트') && selectedPlan?.quarter_price && <option value="quarter_price">3개월</option>}
                                    {!selectedPlan?.name.includes('테스트') && selectedPlan?.half_year_price && <option value="half_year_price">6개월</option>}
                                    {!selectedPlan?.name.includes('테스트') && selectedPlan?.year_price && <option value="year_price">1년</option>}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>마감일자</Label>
                                <Input type="date" ref={expiredAtRef} value={expiredAt} invalid={!!errors?.expiredAt} disabled />
                                {errors.expiredAt && <FormFeedback>{errors.expiredAt}</FormFeedback>}
                            </Col>
                        </FormGroup>
                        <FormGroup className="position-relative" row>
                            <Col>
                                <Label>비용</Label>
                                <Input type="text" ref={totalAmountRef} value={totalAmount} disabled invalid={!!errors.totalAmount} onChange={(e) => setTotalAmount(e.target.value)} />
                                {errors.totalAmount && <FormFeedback>{errors.totalAmount}</FormFeedback>}
                            </Col>
                        </FormGroup>
                        </>
                    )}
                    <FormGroup className="position-relative" row>
                        <Col>
                            <Label>비고</Label>
                            <Input type="textarea" ref={remarksRef} value={remarks} invalid={!!errors.remarks} onChange={(e) => setRemarks(e.target.value)} />
                            {errors.remarks && <FormFeedback>{errors.remarks}</FormFeedback>}
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary">
                        확인
                    </Button>{' '}
                    <Button color="light" onClick={toggle}>
                        취소
                    </Button>
                </ModalFooter>
                </Form>
            </Modal>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>사용자 삭제</ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: 'center' }}>
                        <AlertCircle width={64} height={64} color="rgb(255 0 0 / 50%)" /><br />
                        정말 해당 사용자를 삭제하시겠습니까?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleDeleteUser}>
                        확인
                    </Button>{' '}
                    <Button color="light" onClick={() => {
                        toggleDeleteModal()
                        setDeleteUserIdx('')
                    }}>
                        취소
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default Project;